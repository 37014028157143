<template>
  <div class="level">
    <div class="level-left" />
    <div class="level-right">
      <div class="level-item">
        <div class="buttons">
          <b-button
            disabled
            icon-left="wrench"
          >
            Customize
          </b-button>

          <b-dropdown
            aria-role="list"
            postition="is-bottom-left"
          >
            <b-button
              slot="trigger"
              icon-left="download"
              icon-right="chevron-down"
            >
              Export
            </b-button>

            <b-dropdown-item
              aria-role="listitem"
              @click="$emit('export-pdf')"
            >
              Export as PDF
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
              aria-role="listitem"
              @click="$emit('export-xlsx')"
            >
              Export as Excel
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'TableHeader',

};
</script>

<style>
</style>
