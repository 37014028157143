<template>
  <div class="level dashboard-navbar">
    <div class="level-left">
      <div class="level-item">
        <div class="content">
          <h1 class="title is-capitalized has-text-primary">
            <slot name="icon" />
            {{ name }}
          </h1>
        </div>
      </div>
    </div>

    <div class="level-right">
      <div class="level-item">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
